@font-face {
    font-family: "SourceSans3 Light";
    src: url("fonts/SourceSans3-Light.woff2") format("woff2");
    font-display: swap;
  }


  @font-face {
    font-family: "SourceSans3";
    src: url("fonts/SourceSans3-Regular.woff2") format("woff2");
    font-display: swap;
  }


  @font-face {
    font-family: "SourceSans3 SemiBold";
    src: url("fonts/SourceSans3-SemiBold.woff2") format("woff2");
    font-display: swap;
  }

  html {
    overflow-y: auto;
    overflow-x: hidden;
  }

  body {
    overflow: hidden;
  }

  main {
    min-height: 100vh;
    background-color: #0e0e0e;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .fade-in {
    opacity: 0;
    animation: fade-in 1s 1;
    animation-fill-mode: forwards;
    transition-timing-function: ease-in;
  }

  .delay-1 {
    animation-delay: 0.5s;
  }
